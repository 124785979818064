<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
  >
    <path d="M0 5.3h24L12 18.7 0 5.3z" />
  </svg>
</template>
